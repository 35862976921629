<template>
  <section class="land-feed-container">
    <!-- feed 顶部广告 -->
    <AdComponent v-if="themeConfig.landFeedAdPos.includes(0) && !componentADed"
      adType="land_feed_ad" :adPosition="0" />

    <div class="feed-title">
      Recommend
    </div>
    <van-list v-model="loading" :finished="finished" :finished-text="bomTitle" :offset="20"
      class="land-feed" loading-text="Loading..." :immediate-check="false" @load="onLoad">
      <div class="newlist_row">
        <template v-for="(item, index) in adjustedItems">
          <component :is="item.componentType" v-if="item.adtype === 'component'"
            :key="`key_${index}`" :item="item.data"
            :class="[`item-theme-${item.data.type === 1 ? 'lead' : 'collection'}`, invalidClass(item.data.type === 1 ? 'lead' : 'collection')]"
            @customClick="goLanding(item.data.id)" />
          <div v-else-if="item.adtype === 'ad' && !componentADed" :key="`key-ad_${index}`"
            class="adsItem ad-row">
            <ins :ref="`ads_pos_${item.position}`" class="adsbygoogle" v-bind="getAdConfig(item)" />
            <div v-if="showDebug" class="ad-msg">
              <div v-if="item.indexKey >= 0">
                position={{ `land_feed_insert_ad[${item.indexKey}]` }}
              </div>
              <div v-else>
                position={{ `land_feed_insert_ad_${item.position}` }}
              </div>
              adconfig={{ getAdConfig(item) }}
            </div>
          </div>
        </template>
      </div>
    </van-list>

    <!-- feed 底部广告 -->
    <AdComponent v-if="themeConfig.landFeedAdPos.includes(2) && !componentADed"
      adType="land_feed_ad" :adPosition="2" />
  </section>
</template>

<script>
import adjustedItemsMixin from '@/mixin/adjustedItemsMixin.js';
import AdComponent from '@/pages/components/AdComponent.vue'

export default {
  components: { AdComponent },
  mixins: [adjustedItemsMixin],
  props: {
    componentADed: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  computed: {
    adjustedItems () {
      return this.getAdjustedItems(this.themeConfig, this.list, 'landFeedInsertAdPos', 'landFeedLeadType', 'landFeedCollectionType', 2);
    }
  },
  async mounted () {
    console.log('-------------------mounted')
    this.initData()
  },
  methods: {
    invalidClass (type) {
      if (type === 'lead' && !this.themeConfig.landFeedLeadType) {
        return 'params-invalid'
      }
      if (type === 'collection' && !this.themeConfig.landFeedCollectionType) {
        return 'params-invalid'
      }
      return ''
    },
    getAdConfig (item) {
      const postion = item.position
      const indexkye = item.indexKey
      const adtype = 'land_feed_insert_ad'
      if (indexkye != -1) {
        console.log(`feed 广告位置对应new：${adtype}[${indexkye}]`);
        return this.adsense[adtype][indexkye] || {};
      } else {
        console.log(`广告位置对应：${adtype}_${postion}`);
        return this.adsense[`${adtype}_${postion}`] || {};
      }
    },
    goLanding (id) {
      console.log(id);
      this.$emit('feedClicked', id);
      // this.$eventrackFb('news_goto_detail', 'click', {
      //   id
      // });
      // this.$router.replace({ name: 'landing', query: { id, thme: this.themeConfig.landType } });
    },
    async initData () {
      console.log('loadList');
      try {
        await this.getList();
      } catch (error) {
        console.error('An error occurred in getList:', error);
        // 可以在这里添加错误处理逻辑
      }
    },
  },
};
</script>